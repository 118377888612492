import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { StorageServiceService } from '../services/storage-service.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private localStorageToken: StorageServiceService,
    private authService: AuthenticationService,
    public storage: StorageServiceService,
  ) { }
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = this.localStorageToken.getToken();
    const data = localStorage.getItem('data');
    let isWebsiteLoggedin = this.authService.getCookie('isWebsitelogin');
    // console.log("🚀 ~ file: auth.guard.ts ~ line 26 ~ AuthGuard ~ canActivate ~ data", data)
    if(!isWebsiteLoggedin){
      this.router.navigate(['/website-login']);
      return true;
    }else if(state.url=='/website-login' && isWebsiteLoggedin){
      this.router.navigate(['/']);
      return true;
    }else if (data) {
      if (token) {
        const tokenDecode = JSON.parse(atob(token.split('.')[1]));
        if (! await this.tokenExpired(tokenDecode.exp)) {
          return true;
        }
      }
      localStorage.removeItem('token');
      if(Math.floor(new Date().getTime() / 1000) >= JSON.parse(atob(JSON.parse(localStorage.getItem('data')).login_token.split('.')[1])).exp ){
        // localStorage.removeItem('data');
        // this.router.navigateByUrl('/login');
        this.authService.logout();
      } else {

        this.router.navigateByUrl('/mpin');
      }

      return false;
    } else {
      this.router.navigateByUrl('/login');
      return false;
    }
  }
  public tokenExpired = async (expiration) => {
    // return Math.floor(new Date().getTime() / 1000) >= expiration;
    if (Math.floor(new Date().getTime() / 1000) >= expiration-100) {
      try {
        // const response = await lastValueFrom(this.authService.refreshToken());
       let login_token= JSON.parse(
          localStorage.getItem('data')
        ).login_token;
      //  console.log("🚀 ~ file: auth.guard.ts ~ line 50 ~ AuthGuard ~ tokenExpired= ~ login_token", login_token)
        //let response: any = await this.authService.refreshToken(localStorage.getItem('token')).pipe(debounceTime(100)).toPromise();
        let response: any = await this.authService.refreshToken(login_token).pipe(debounceTime(100)).toPromise();

        localStorage.setItem('token', response);
        this.storage.get('mpinData').then((res: any) => {
          res.mpinToken = response;
          this.storage.store('mpinData', res).then(res => {
            return false
          });

        });
       // return false
      }
      catch (ex) {
        // console.log("🚀 ~ file: auth.guard.ts ~ line 50 ~ AuthGuard ~ tokenExpired=async ~ ex", ex)
        return true
      }
    } else {
      // return Math.floor(new Date().getTime() / 1000) >= expiration;
      return false
    }

  }
}


