import {
  Component,
  OnInit,
  HostListener,
  ElementRef,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { Router, RoutesRecognized, NavigationEnd } from '@angular/router';
import { AlertController, Platform } from '@ionic/angular';
import { filter, pairwise } from 'rxjs/operators';
import { StorageServiceService } from './services/storage-service.service';
import { Location } from '@angular/common';
import { Network, Connection } from '@awesome-cordova-plugins/network/ngx';
import { ToastService } from './services/toast.service';
import { AuthenticationService } from './services/authentication.service';
import { GlobalService } from './services/global.service';
import { UiControllService } from './services/ui-controll.service';
//import OneSignal from 'onesignal-cordova-plugin';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit,AfterViewInit {
  @ViewChild('appContainer') appContainer: ElementRef;
  prevScrollPos: number = 0;
  disconnectSubscription: any;
  networkAlert: any;
  connectSubscription: any;
  isNetwork = false;
  currentRoute: any;
  constructor(
    public storageServiceService: StorageServiceService,
    public router: Router,
    private platform: Platform,
    private network: Network,
    private alertCtrl: AlertController,
    public toastService: ToastService,
    private _location: Location,
    public auth: AuthenticationService,
    public globalService: GlobalService,
    private el: ElementRef,
    private scrollService: UiControllService
  ) {
    // this.auth._defaultMetaTags();
    // this.router.events
    // .pipe(filter((event: any) => event instanceof RoutesRecognized), pairwise())
    // .subscribe((events: RoutesRecognized[]) => {
    //      this.storageServiceService.previousUrl = events[0].urlAfterRedirects;

    // });
    globalService.loadRouting();
  }

  @HostListener('window:scroll', ['$event'])
  myFn(event: Event): void {
    const currentScrollPos = (event.target as HTMLElement).scrollTop;
    const header = document.querySelector('header');
    if (currentScrollPos > 100) {
      header.classList.add('header-fixed');
      // header.style.top = `${currentScrollPos}px`;
    } else {
      header?.classList.remove('header-fixed');
      // header.style.top = `${currentScrollPos}px`;
    }

    // Update the previous scroll position
    this.prevScrollPos = currentScrollPos;
  }

  ngOnInit() {
    // this.scrollService.getScrollSubject().subscribe((elementId: string) => {
    //   this.scrollToElement(elementId);
    // });
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((route:any) => {
        this.currentRoute=route.url;
        window.scrollTo(1000, 0);
      });
    this.initializeApp();
    this.checkInternetConnect();
    // this.OneSignalInit();
  }

  // scrollToElement(elementId: string): void {
  //   const element = document.getElementById(elementId);
  //   if (element) {
  //     element.scrollIntoView({
  //       behavior: 'smooth',
  //       block: 'start',
  //       inline: 'start',
  //     });
  //     // console.log(element);
  //   }
  // }

  // OneSignalInit(): void {
  //   // Uncomment to set OneSignal device logging to VERBOSE
  //   // OneSignal.setLogLevel(6, 0);

  //   // NOTE: Update the setAppId value below with your OneSignal AppId.

  //  OneSignal.setAppId("288f2adf-aec7-4552-a87c-c2f93e7b98c2");
  //   OneSignal.setNotificationOpenedHandler(function(jsonData) {
  //       console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
  //   });

  //   // Prompts the user for notification permissions.
  //   //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 7) to better communicate to your users what notifications they will get.
  //   OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
  //       console.log("User accepted notifications: " + accepted);
  //   });
  // }

  initializeApp() {
    this.platform.backButton.subscribeWithPriority(0, (processNextHandler) => {
      let url = this.router.url;
      // console.log(
      //   '🚀 ~ file: app.component.ts ~ line 127 ~ AppComponent ~ this.platform.backButton.subscribeWithPriority ~ this.router.url;',
      //   this.router.url
      // );
      if (url == '/tabs/tab1') {
        navigator['app'].exitApp();
      } else if (url == '/login') {
        //this.showExitConfirm();
        processNextHandler();
      } else if (
        url == '/thank-you?type=SendMoney' ||
        url == '/thank-you?type=PicoMoney'
      ) {
        this.router.navigateByUrl('/tabs/tab1');
      } else {
        // if( this.isNetwork){
        //   return
        // }
        this._location.back();
      }
    });
  }

  checkInternetConnect() {
    this.disconnectSubscription = this.network
      .onDisconnect()
      .subscribe(async () => {
        this.isNetwork = true;
        // console.log('network was disconnected :-(');
        this.networkAlert = await this.createAlert(
          'No Internet',
          false,
          'Please Check you internet Connection and try again',
          {
            text: '',
            role: '',
            cssClass: 'secondary',
            handler: async () => {
              navigator['app'].exitApp();
            },
          }
        );
        this.networkAlert.present();
      });
    this.connectSubscription = this.network.onConnect().subscribe(() => {
      this.isNetwork = false;
      let networkState = this.network.type;
      // console.log(
      //   '🚀 ~ file: app.component.ts:93 ~ AppComponent ~ this.connectSubscription=this.network.onConnect ~ networkState',
      //   networkState
      // );
      setTimeout(() => {
        if (
          this.network.type === Connection.CELL_2G ||
          this.network.type === Connection.CELL_3G
        ) {
          this.toastService.presentToast(
            'Please Check you internet Connection.'
          );
        }
      }, 300);
      // console.log('network connected!');
      if (this.networkAlert) {
        this.networkAlert.dismiss();
        this.isNetwork = false;
        //this.checkUser();
      }
    });
  }
  async createAlert(
    header,
    backdropDismiss,
    message,
    buttonOptions1,
    buttonOptions2?
  ): Promise<HTMLIonAlertElement> {
    const alert = await this.alertCtrl.create({
      header,
      backdropDismiss,
      message,
      buttons: !buttonOptions2
        ? [buttonOptions1]
        : [buttonOptions1, buttonOptions2],
    });
    return alert;
  }
  keyBoardEvent() {
    window.addEventListener('native.keyboardshow', function () {
      window.getSelection().getRangeAt(0).startOffset;
      document.body.classList.add('keyboard-opened');
      /*
       * This code is a hack to fix the cursor issue.
       * When we click on the input field and keyboard appears, the cursor most of the time is not on the right position.
       * It simply needs to refresh UI once. There was no solution to this problem, so I tried a hack, and it worked. ;)
       * i.e. disable it for 10 micro secs and then re-enable it.
       */
      if (document.activeElement.nodeName === 'INPUT') {
        setTimeout(function () {
          document.activeElement['disabled'] = true;
          setTimeout(function () {
            document.activeElement['disabled'] = false;
          }, 10);
        }, 350);
      }
    });
  }
  _checkWidthCondition() {
    if (
      this.router.url == '/' ||
      this.router.url == 'exchange' ||
      this.router.url == '/news' ||
      this.router.url == '/contact' ||
      this.router.url.includes('/email-verify') ||
      this.router.url == '/#book-fx-section'
    ) {
      return true;
    } else {
      return false;
    }
  }
  ngAfterViewInit(){
    let userDetail:any=localStorage.getItem('data');
    if(userDetail){
      userDetail=JSON.parse(userDetail).userData;
      (<any>window).Intercom("update", {
        name: userDetail?.firstname,
        user_id: userDetail?.user_id,
        email: userDetail?.email
      });
    }
  }
}
