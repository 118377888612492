import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AutoLoginGuard } from './guards/auto-login.guard';
import { AuthGuard } from './guards/auth.guard';
import { KycCheckGuard } from './guards/kyc-check.guard';
import { PublicRouteGuard } from './guards/public-route.guard';
import { CustomPreloadingStrategy } from './preLoadingStrategy';

const routes: Routes = [
  //----------------------------------Route for website--------------------------------
  {
    path: '',
    canActivate: [PublicRouteGuard],
    loadChildren: () =>
      import('./exchange/exchange.module').then((m) => m.ExchangeModule),
  },
  {
    path: 'exchange',
    redirectTo: '',
  },
  //-------------------------Route for ionic--------------------------------
  {
    path: 'login',
    canActivate: [PublicRouteGuard],
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule),
    canLoad: [AutoLoginGuard], // Check if we should show the introduction or forward to inside
  },
  {
    path: '',
    redirectTo: '/instore',
    pathMatch: 'full',
  },
  {
    path: 't/:tinyUrl',
    loadChildren: () =>
      import('./pages/invoice-download/invoice-download.module').then((m) => m.InvoiceDownloadModule),
  },
  {
    path: 'transaction',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./tab2/tab2.module').then((m) => m.Tab2PageModule),
  },
  {
    path: 'recipients',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./tab3/tab3.module').then((m) => m.Tab3PageModule),
  },
  {
    path: 'setting',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./tab4/tab4.module').then((m) => m.Tab4PageModule),
  },
  {
    path: 'reg-thankyou',
    loadChildren: () =>
      import('./pages/registration-thankyou/registration-thankyou.module').then(
        (m) => m.RegistrationThankyouModule
      ),
  },
  {
    path: 'additional-info',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/additional-info/additional-info.module').then(
        (m) => m.AdditionalInfoModule
      ),
  },
  {
    path: 'onfido-process',
    canActivate: [AuthGuard, KycCheckGuard],
    loadChildren: () =>
      import('./onfido-page/onfido-page.module').then(
        (m) => m.OnfidoPageModule
      ),
  },
  {
    path: 'reg-type',
    loadChildren: () =>
      import('./pages/registration/reg-type/reg-type.module').then(
        (m) => m.RegTypePageModule
      ),
  },
  {
    path: 'business-reg',
    loadChildren: () =>
      import('./pages/registration/business-reg/business-reg.module').then(
        (m) => m.BusinessRegPageModule
      ),
  },
  {
    path: 'personal-reg',
    canActivate: [PublicRouteGuard],
    loadChildren: () =>
      import('./pages/registration/personal-reg/personal-reg.module').then(
        (m) => m.PersonalRegPageModule
      ),
  },
  {
    path: 'email-otp',
    canActivate: [PublicRouteGuard],
    loadChildren: () =>
      import('./pages/registration/email-otp/email-otp.module').then(
        (m) => m.EmailOtpPageModule
      ),
  },
  {
    path: 'mobile-otp',
    canActivate: [PublicRouteGuard],
    loadChildren: () =>
      import('./pages/registration/mobile-otp/mobile-otp.module').then(
        (m) => m.MobileOtpPageModule
      ),
  },
  {
    path: 'tab4',
    loadChildren: () =>
      import('./tab4/tab4.module').then((m) => m.Tab4PageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./pages/profile/profile.module').then((m) => m.ProfilePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'change-password',
    loadChildren: () =>
      import('./pages/profile/change-password/change-password.module').then(
        (m) => m.ChangePasswordPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'dynamic-content/:name',
    loadChildren: () =>
      import('./pages/dynamic-content/dynamic-content.module').then(
        (m) => m.DynamicContentPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'payment-method/delivery',
    loadChildren: () =>
      import('./pages/payment-method/payment-method.module').then(
        (m) => m.PaymentMethodPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'payment-method/pickup-store',
    loadChildren: () =>
      import('./pages/payment-method/payment-method.module').then(
        (m) => m.PaymentMethodPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'bank-transfer/pickup-store',
    loadChildren: () =>
      import('./pages/bank-transfer/bank-transfer.module').then(
        (m) => m.BankTransferPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'bank-transfer/delivery',
    loadChildren: () =>
      import('./pages/bank-transfer/bank-transfer.module').then(
        (m) => m.BankTransferPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'thank-you/delivery',
    loadChildren: () =>
      import('./pages/thank-you/thank-you.module').then(
        (m) => m.ThankYouPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'thank-you/pickup-store',
    loadChildren: () =>
      import('./pages/thank-you/thank-you.module').then(
        (m) => m.ThankYouPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'unsuccessful_payment/delivery',
    loadChildren: () =>
      import('./pages/payment-failed/payment-failed.module').then(
        (m) => m.PaymentFailedModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'unsuccessful_payment/pickup-store',
    loadChildren: () =>
      import('./pages/payment-failed/payment-failed.module').then(
        (m) => m.PaymentFailedModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'date-range',
    loadChildren: () =>
      import('./pages/date-range/date-range.module').then(
        (m) => m.DateRangePageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'money-order/pickup-store',
    loadChildren: () =>
      import('./pages/money-order/money-order.module').then(
        (m) => m.MoneyOrderPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'money-order/delivery',
    loadChildren: () =>
      import('./pages/money-order/money-order.module').then(
        (m) => m.MoneyOrderPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'collection-date',
    loadChildren: () =>
      import('./pages/collection-date/collection-date.module').then(
        (m) => m.CollectionDatePageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'your-order/pickup-store',
    canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/your-order/your-order.module').then(
            (m) => m.YourOrderPageModule
          ),
  },
  {
    path: 'your-order/delivery',
    canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/your-order/your-order.module').then(
            (m) => m.YourOrderPageModule
          ),
  },
  {
    path: 'pico-branch',
    loadChildren: () =>
      import('./pages/pico-branch/pico-branch.module').then(
        (m) => m.PicoBranchPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'branches',
    loadChildren: () =>
      import('./pages/branches/branches.module').then(
        (m) => m.BranchesPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'forgot-password',
    canActivate: [PublicRouteGuard],
    loadChildren: () =>
      import('./pages/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordPageModule
      ),
  },
  {
    path: 'send-pico-money-recipents',
    loadChildren: () =>
      import(
        './pages/send-pico-money-recipents/send-pico-money-recipents.module'
      ).then((m) => m.SendPicoMoneyRecipentsPageModule),
  },
  {
    path: 'add-pico-money-recipents',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './pages/add-pico-money-recipents/add-pico-money-recipents.module'
      ).then((m) => m.AddPicoMoneyRecipentsPageModule),
  },
  {
    path: 'pico-money-transaction-details',
    loadChildren: () =>
      import(
        './pages/pico-money-transaction-details/pico-money-transaction-details.module'
      ).then((m) => m.PicoMoneyTransactionDetailsPageModule),
  },
  {
    path: 'country-selection',
    loadChildren: () =>
      import('./modal/country-selection/country-selection.module').then(
        (m) => m.CountrySelectionPageModule
      ),
  },
  {
    path: 'opertion',
    loadChildren: () =>
      import('./modal/opertion/opertion.module').then(
        (m) => m.OpertionPageModule
      ),
  },
  {
    path: 'pico-money-trasaction',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/pico-money-trasaction/pico-money-trasaction.module').then(
        (m) => m.PicoMoneyTrasactionPageModule
      ),
  },
  {
    path: 'email-verification',
    loadChildren: () =>
      import('./pages/email-verification/email-verification.module').then(
        (m) => m.EmailVerificationPageModule
      ),
  },

  {
    path: 'phone-verification',
    loadChildren: () =>
      import('./pages/phone-verification/phone-verification.module').then(
        (m) => m.PhoneVerificationPageModule
      ),
  },
  {
    path: 'online-transfer/delivery',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/stripe-native/stripe-native.module').then(
        (m) => m.StripeNativePageModule
      ),
  },
  {
    path: 'online-transfer/pickup-store',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/stripe-native/stripe-native.module').then(
        (m) => m.StripeNativePageModule
      ),
  },
  {
    path: 'instore',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/instore/instore.module').then((m) => m.InstorePageModule),
  },
  {
    path: 'website-login',
    loadChildren: () =>
      import('./pages/website-login/website-login.module').then((m) => m.WebsiteLoginModule),
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: CustomPreloadingStrategy,
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
  providers: [CustomPreloadingStrategy],
})
export class AppRoutingModule {}
