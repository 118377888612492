import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class PublicRouteGuard implements CanActivate {
  constructor(private router:Router, private authService:AuthenticationService){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let isWebsiteLoggedin = this.authService.getCookie('isWebsitelogin');
      const data = localStorage.getItem('data');
      if(!isWebsiteLoggedin){
        this.router.navigate(['/website-login']);
        return true;
      }else if(state.url=='/website-login' && isWebsiteLoggedin){
        this.router.navigate(['/']);
        return true;
      }else if(data && (state.url!='/' && state.url!='/exchange')){
        this.router.navigateByUrl('/instore')
        return true;
      }else {
        return true;
      }
  }

}
